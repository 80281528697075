//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-920:_3392,_6296,_6064,_9704,_3964,_580,_1008,_5042;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-920')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-920', "_3392,_6296,_6064,_9704,_3964,_580,_1008,_5042");
        }
      }catch (ex) {
        console.error(ex);
      }